<template>
  <header>
    <div
      style="gap: 1.2rem"
      class="text-center m-auto pt-3 d-flex px-3 justify-content-between d-lg-none"
    >
      <router-link class="bg-transparent navbar-brand pl-lg-3" to="/">
        <img
          :src="logo_link ? logo_link : '/images/default.png'"
          width="100px"
          alt="Logo"
        />
      </router-link>
      <p class="font-weight-bold display-4" v-if="isAuthenticated">
        {{ $t('header.hi') }}, {{ this.username }}!
      </p>
      <b-navbar-toggle target="nav-inner-primary">
        <button class="btn-mobile-nav">
          <em class="fa-solid fa-bars icon-mobile-nav" @click="openNav()"></em>
          <em
            class="fa-solid fa-xmark icon-mobile-nav d-none"
            @click="closeNav()"
          ></em>
        </button>
      </b-navbar-toggle>
    </div>
    <b-navbar toggleable="sm" type="light" class="top-bar">
      <div class="container">
        <div
          class="d-none d-lg-block"
          style="width: 220px"
          v-if="isAuthenticated"
        ></div>
        <p
          class="d-none d-lg-block mt-3 font-weight-bold display-4"
          v-if="isAuthenticated"
        >
          <b-skeleton
            width="150px"
            height="25px"
            v-if="!logo_link"
          ></b-skeleton>
          <span v-else> {{ $t('header.hi') }}, {{ this.username }}!</span>
        </p>

        <ul
          v-if="!logo_link"
          class="navbar-nav navbar--top ml-lg-auto mt-lg-0 mt-3 px-lg-0 px-3 py-3 py-md-0"
        >
          <b-skeleton width="75px" class="mr-2"></b-skeleton>
          <b-skeleton leton width="75px" class="mr-2"></b-skeleton>
          <b-skeleton leton width="50px" class="mr-2"></b-skeleton>
        </ul>
        <ul
          v-else
          class="navbar-nav navbar--top ml-lg-auto mt-lg-0 mt-3 px-lg-0 px-3 py-3 py-md-0"
        >
          <li class="nav-item top-bar-lang mt-md-1" style="margin-right: 10px">
            <b-dropdown
              v-if="areaSetting.length > 0"
              variant="second"
              :text="getArea"
              size="small"
              right
            >
              <b-dropdown-item
                v-for="(item, index) in areaSetting"
                :key="index"
                @click="setArea(item)"
              >
                <span> {{ item }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </li>
          <li class="nav-item top-bar-lang mt-md-1">
            <b-dropdown
              v-if="languageSetting.length > 0"
              variant="second"
              :text="getLanguage"
              size="small"
              right
            >
              <b-dropdown-item
                v-for="(item, index) in languageSetting"
                :key="index"
                @click="setLang(item)"
                ><span v-if="item == 'hk'"> {{ $t('lang.hk') }} </span>
                <span v-else-if="item == 'cn'"> {{ $t('lang.cn') }} </span>
                <span v-else> {{ $t('lang.en') }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </li>
          <li class="nav-item top-bar-icon">
            <a class="nav-link"
              ><img src="@/assets/img/icon/icon-call.png" alt="Image" />
              {{ telephone }}</a
            >
          </li>
          <li
            class="nav-item item-driver-right top-bar-icon"
            v-if="isAuthenticated"
          >
            <router-link class="nav-link" to="/my-order"
              ><img src="@/assets/img/icon/icon-order.png" alt="Image" />
              {{ $t('Myorder.header') }}</router-link
            >
          </li>
          <li class="nav-item">
            <a
              id="logout-link"
              v-if="isAuthenticated"
              class="nav-link"
              href="javascript:void(0);"
              v-on:click="logOut(1)"
              ><span class="pl-0">{{ $t('Logout') }}</span></a
            >
            <span v-else>
              <a
                id="login-register-link"
                v-on:click="changTab(0)"
                class="nav-link login-link"
                href="javascript:void(0);"
                v-b-modal="'modal-b2'"
                >{{ $t('register.register') }}</a
              >
              <a
                id="login-link"
                v-on:click="changTab(1)"
                class="nav-link login-link"
                href="javascript:void(0);"
                v-b-modal="'modal-b2'"
                >{{ '/' + $t('login.login') }}</a
              >
            </span>
          </li>
        </ul>
      </div>
    </b-navbar>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="menus">
        <b-container class="justify-content-center">
          <b-navbar-brand>
            <router-link
              class="navbar-brand pl-3 d-none d-lg-block bg-transparent mb-3"
              to="/"
            >
              <b-skeleton
                v-if="!logo_link"
                type="avatar"
                height="100px"
                width="100px"
              ></b-skeleton>
              <img v-else :src="logo_link" id="global_logo" alt="Logo" />
            </router-link>
          </b-navbar-brand>
          <div v-if="menus">
            <p class="text-center font-weight-bold pt-3 pt-lg-0">
              <span style="color: goldenrod">
                {{ $t('header.major_brands') }}:
              </span>
              {{ $t('header.menus_title') }}
            </p>
            <b-collapse id="nav-inner-primary" v-if="menus.length" is-nav>
              <div
                class="container-opacity left text-center d-none d-lg-block"
                @click="swipeLeft()"
              >
                <img
                  src="@/assets/img/icon/angle-left-solid.svg"
                  width="10px"
                  alt="Image"
                />
              </div>
              <ul class="navbar-nav ml-lg mt-md-0 mt-2 ml-lg-4" id="content">
                <li
                  v-for="item in menus"
                  :key="item.id"
                  class="nav-item d-flex m-2"
                >
                  <em
                    v-show="item.menuType == 'both'"
                    :style="{
                      background:
                        'linear-gradient(15deg, ' +
                        item.hexCode +
                        ' 9.85%, ' +
                        item.hexCode +
                        ' 89.79%)',
                    }"
                    :class="'i__icon--menu text-white fas fa-' + item.menuIcon"
                  ></em>
                  <router-link
                    v-if="lang == 'cn' && item.type != 'url'"
                    class="nav-link"
                    :to="setLink(item.link, item.type)"
                    >{{ item.cnLabel }}</router-link
                  >
                  <router-link
                    v-if="lang == 'hk' && item.type != 'url'"
                    class="nav-link"
                    :to="setLink(item.link, item.type)"
                    >{{ item.hkLabel }}</router-link
                  >
                  <router-link
                    v-if="lang == 'en' && item.type != 'url'"
                    class="nav-link"
                    :to="setLink(item.link, item.type)"
                    >{{ item.enLabel }}</router-link
                  >
                  <div :class="{ activeUrl: isActive(item) }">
                    <a
                      v-if="lang == 'cn' && item.type == 'url'"
                      class="nav-link"
                      :href="item.link"
                      >{{ item.cnLabel }}</a
                    >
                    <a
                      v-if="lang == 'hk' && item.type == 'url'"
                      class="nav-link"
                      :href="item.link"
                      >{{ item.hkLabel }}</a
                    >
                    <a
                      v-if="lang == 'en' && item.type == 'url'"
                      class="nav-link"
                      :href="item.link"
                      >{{ item.enLabel }}</a
                    >
                  </div>
                </li>
              </ul>
              <div
                class="container-opacity right text-center d-none d-lg-block pl-3"
                @click="swipeRight()"
              >
                <img
                  src="@/assets/img/icon/angle-right-solid.svg"
                  width="10px"
                  alt="Image"
                />
              </div>
            </b-collapse>
          </div>
          <div v-else class="w-75">
            <b-skeleton width="60%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
          </div>
        </b-container>
      </b-navbar>
    </div>
  </header>
</template>
<script>
import $ from 'jquery';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelopeOpenText,
  faParachuteBox,
  faObjectGroup,
  faPager,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faTwitter,
  faDribbble,
  faPinterest,
  faInstagram,
  faGooglePlus,
} from '@fortawesome/free-brands-svg-icons';
import config from '@/global/config.js';
const logoDefault = require('@/assets/images/default.png');

library.add(
  faEnvelopeOpenText,
  faParachuteBox,
  faObjectGroup,
  faPager,
  faFacebookSquare,
  faTwitter,
  faDribbble,
  faPinterest,
  faGooglePlus,
  faInstagram,
);

export default {
  data() {
    return {
      menus: null,
      loading: true,
      errored: false,
      infolength: null,
      slug: null,
      telephone: null,
      logo_link: null,
      lang: null,
      areaOrg: null,
      fullPath: window.location.href,
      languageSetting: [],
      areaSetting: [],
    };
  },

  computed: {
    username() {
      return this.$store.getters['authModule/getUserName'];
    },
    isAuthenticated() {
      return this.$store.getters['authModule/getAuthentication'];
    },
    getLanguage() {
      let content = '';
      switch (this.$store.getters.getLanguage) {
        case 'zh-HK':
          content = this.$t('lang.hk');
          this.lang = 'hk';
          break;
        case 'zh':
          content = this.$t('lang.cn');
          this.lang = 'cn';
          break;
        case 'en':
          content = this.$t('lang.en');
          this.lang = 'en';
          break;
      }
      return content;
    },
    getArea() {
      let content = 'HKG';
      switch (this.areaOrg) {
        case 'HKG':
          content = 'HKG';
          break;
        case 'SH':
          content = 'SH';
          break;
        case 'CD':
          content = 'CD';
          break;
      }
      return content;
    },
  },

  mounted: function () {
    // setTimeout(() => {
    //get site
    const site = JSON.parse(localStorage.getItem('site'));
    this.areaOrg = localStorage.getItem('areaOrg');
    this.logo_link = site.logo.link
      ? config.oss_path + site.logo.link
      : logoDefault;
    this.menus = site.menus;
    this.telephone = site.phone.phoneNumber;
    if (localStorage.lang_frontend) {
      this.lang = localStorage.lang_frontend;
    } else {
      this.lang = 'hk';
    }
    this.$store.dispatch('setLang', this.lang);
    this.languageSetting = site.languageSetting;
    this.areaSetting = site.areaSetting;
    // this.$store
    // .dispatch('headerModule/getAllSite')
    // .then(() => {
    //   this.languageSetting = this.$store.getters['headerModule/getSite'].languageSetting;
    //   this.areaSetting = this.$store.getters['headerModule/getSite'].areaSetting;
    // this.$store
    //   .dispatch('parametersModule/getParameters')
    //   .then(() => {
    // this.languageSetting =
    //   this.$store.getters[
    //     'parametersModule/getParameters'
    //   ].languageSetting;

    if (this.languageSetting.length == 1) {
      this.setLang(this.languageSetting[0]);
    }
    // })
    // .catch((error) => {
    //   this.errored = true;
    // })
    // .finally(() => (this.loading = false));

    //check login
    this.keepLogin();
  },
  methods: {
    isActive: function (item) {
      if (item.link.includes(this.$route.fullPath)) {
        return true;
      }
      return false;
    },
    setLink: function (link, type) {
      if (type === 'page') {
        return '/page/' + link;
      } else if (type === 'package') {
        return '/tour/' + link;
      }
    },
    setLang: function (lang) {
      this.$store.dispatch('setLang', lang);
    },
    setArea: function (item) {
      localStorage.removeItem('fuson-web')
      localStorage.removeItem('site')
      localStorage.setItem('areaOrg', item);
      this.areaOrg = item;
      window.location.reload();
    },
    logOut: function () {
      this.$store.dispatch('authModule/logout');
      this.$store.dispatch('paymentModule/clear');
    },
    keepLogin: function () {
      const exp = localStorage.getItem('exp');
      var today = new Date();
      var unixTimeStamp = Math.floor(today.getTime() / 1000);
      if (exp === null) {
        return;
      }
      if (exp < unixTimeStamp) {
        this.logOut();
        return;
      }

      this.$store.dispatch('authModule/keeplogin');
    },
    changTab: function (value) {
      this.$root.$refs.A.setTabIndex(value);
    },
    swipeLeft() {
      $('#content').animate(
        {
          scrollLeft: '-=300px',
        },
        'slow',
      );
    },
    swipeRight() {
      $('#content').animate(
        {
          scrollLeft: '+=300px',
        },
        'slow',
      );
    },
  },
  watch: {
    '$route.fullPath': function () {
      this.fullPath = window.location.href;
    },
  },
};
</script>
<style>
#logo {
  padding: 0.5rem 2rem;
  background-color: #0e6dca;
  color: #fff;
  font-weight: bold;
}
.activeUrl {
  background-color: orange;
  border-radius: 0.2rem;
  font-weight: bold;
}
.activeUrl > a {
  color: #fff !important;
}
.login-link {
  display: inline-block;
}
a#login-register-link {
  padding-right: 0;
}
a#login-link {
  padding-left: 0;
}
#global_logo {
  position: relative;
  max-width: 150px;
}
span.nav-link-inner--text {
  color: #3e3c3c;
}
.top-bar-icon img {
  vertical-align: middle;
  width: 13px;
}

#nav-inner-primary .navbar-nav {
  overflow: hidden;
  white-space: nowrap;
}

.nav-link {
  font-size: 12px;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
li.nav-item.nav-driver-left {
  margin-left: 15px;
}
li.nav-item.nav-item-driver a.nav-link,
.e-tab-text a {
  padding-right: 1px;
  padding-left: 1px;
}

.navbar.navbar-main {
  min-height: 4rem;
  position: relative;
}

#nav-inner-primary .navbar-collapse {
  white-space: nowrap;
  overflow: hidden;
}

#nav-inner-primary .navbar-nav .nav-item.active .nav-link {
  color: #fff !important;
}
#nav-inner-primary .navbar-nav .nav-item,
#element .e-toolbar-item {
  margin: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  white-space: nowrap;
}

.container-opacity {
  font-size: 2.2rem;
  width: 60px;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
  cursor: pointer;
  position: relative;
  bottom: 5px;
}

.bg-info {
  background-color: #fff !important;
}

.container-opacity.right {
  right: 0;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 100%, 0.7) 50%,
    #fff
  );
}

.container-opacity.left {
  left: 10px;
}

#nav-inner-primary .navbar-nav .nav-item.active {
  background-color: orange;
  border-radius: 0.2rem;
  font-weight: bold;
  color: #fff !important;
}

a.router-link-active {
  background-color: orange;
  border-radius: 0.2rem;
  font-weight: bold;
  color: #fff !important;
}

.e-tab .e-tab-header .e-indicator {
  display: none !important;
}

#nav-inner-primary .navbar-nav .nav-link,
.e-tab-text a {
  padding: 0.1rem 0.5rem;
  color: #333333;
  font-size: 0.9rem;
  font-weight: 500;
}

.top-bar {
  padding-top: 1rem;
}

.top-bar-lang button {
  background-color: #006bba;
  font-size: 0.6rem;
  padding: 1px 7px;
  border: unset;
  box-shadow: unset;
}

@media only screen and (max-width: 1140px) {
  #nav-inner-primary .navbar-nav .nav-item {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}

@media (min-width: 991px) {
  #nav-inner-primary {
    width: 800px;
  }
}

@media (max-width: 991px) {
  .navbar--top {
    display: contents;
  }

  .display-4 {
    font-size: 1.3rem;
  }
}

.navbar-dark .navbar-toggler {
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 2.4px 15px;
  font-size: 16px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.65);
  border-color: #eceee7;
  background: #006bba;
}

.i__icon--menu {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  font-size: 12px;
  margin-right: 5px;
  text-align: center;
  padding-top: 7px;
}

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
}

.icon-mobile-nav {
  font-size: 2rem;
  color: #333;
}
</style>
